import React, { FunctionComponent } from 'react';

export const PrivacyPolicyPage : FunctionComponent<{}> = () => {
  return (
    <>
      <h1>Політика конфіденційності</h1>
      <p>Останнє оновлення: 28 серпня 2022 р.</p>
      <p>Ця Політика конфіденційності описує нашу політику та процедури щодо збору, використання та розкриття вашої інформації, коли ви користуєтеся Сервісом, а також розповідає про ваші права на конфіденційність і те, як закон захищає вас.</p>
      <p>Ми використовуємо Ваші особисті дані для надання та покращення Сервісу. Користуючись Сервісом, ви погоджуєтеся на збір і використання інформації відповідно до цієї Політики конфіденційності.</p>
      <h1>Тлумачення та визначення</h1>
      <h2>Тлумачення</h2>
      <p>Слова, початкова літера яких написана великою, мають значення, визначені за таких умов. Наведені нижче визначення мають однакове значення незалежно від того, чи вказано вони в однині чи множині.</p>
      <h2>Визначення</h2>
      <p>Для цілей цієї Політики конфіденційності:</p>
      <ul>
      <li>
      <p><strong>Обліковий запис</strong> означає унікальний обліковий запис, створений для Вас для доступу до нашого Сервісу або його частин.</p>
      </li>
      <li>
      <p><strong>Компанія</strong> (у цій Угоді згадується як «Компанія», «Ми», «Нас» або «Наш») відноситься до UALYRICS.</p>
      </li>
      <li>
      <p><strong>Cookie</strong> – це невеликі файли, які веб-сайт розміщує на вашому комп’ютері, мобільному пристрої чи будь-якому іншому пристрої та містить відомості про вашу історію веб-перегляду на цьому веб-сайті під час багатьох його використання.</p>
      </li>
      <li>
      <p><strong>Країна</strong> стосується: Україна</p>
      </li>
      <li>
      <p><strong>Пристрій</strong> означає будь-який пристрій, який може отримати доступ до Служби, як-от комп’ютер, мобільний телефон або цифровий планшет.</p>
      </li>
      <li>
      <p><strong>Особисті дані</strong> – це будь-яка інформація, яка стосується ідентифікованої або ідентифікованої особи.</p>
      </li>
      <li>
      <p><strong>Сервіс</strong> відноситься до веб-сайту.</p>
      </li>
      <li>
      <p><strong>Постачальник послуг</strong> означає будь-яку фізичну або юридичну особу, яка обробляє дані від імені Компанії. Це стосується сторонніх компаній або осіб, найнятих Компанією для сприяння Сервісу, надання Сервісу від імені Компанії, надання послуг, пов’язаних із Сервісом, або допомоги Компанії в аналізі того, як використовується Сервіс.</ p>
      </li>
      <li>
      <p><strong>Дані про використання</strong> стосуються даних, зібраних автоматично, створених під час використання Служби або з самої інфраструктури Служби (наприклад, тривалість відвідування сторінки).</p>
      </li>
      <li>
      <p><strong>Веб-сайт</strong> посилається на UALYRICS, доступний за адресою <a href="https://ualyrics.com/" rel="external nofollow noopener noreferrer" target="_blank">https://ualyrics .com/</a></p>
      </li>
      <li>
      <p><strong>Ви</strong> означає особу, яка отримує доступ або використовує Службу, або компанію чи іншу юридичну особу, від імені якої така особа отримує доступ або використовує Службу, залежно від обставин.</p>
      </li>
      </ul>
      <h1>Збір і використання ваших персональних даних</h1>
      <h2>Типи зібраних даних</h2>
      <h3>Особисті дані</h3>
      <p>Під час використання нашого Сервісу ми можемо попросити вас надати нам певну особисту інформацію, яка може бути використана для зв’язку з вами або ідентифікації. Особиста інформація може включати, але не обмежуватися:</p>
      <ul>
      <li>
      <p>Адреса електронної пошти</p>
      </li>
      <li>
      <p>Ім'я та прізвище</p>
      </li>
      <li>
      <p>Дані про використання</p>
      </li>
      </ul>
      <h3>Дані про використання</h3>
      <p>Дані про використання збираються автоматично під час використання Сервісу.</p>
      <p>Дані про використання можуть містити таку інформацію, як адреса Інтернет-протоколу вашого пристрою (наприклад, IP-адреса), тип веб-переглядача, версія веб-переглядача, сторінки нашого Сервісу, які ви відвідуєте, час і дату вашого відвідування, час, проведений на цих сторінках , унікальні ідентифікатори пристроїв та інші діагностичні дані.</p>
      <p>Коли ви отримуєте доступ до Сервісу за допомогою мобільного пристрою або через нього, ми можемо автоматично збирати певну інформацію, включаючи, але не обмежуючись, тип мобільного пристрою, який ви використовуєте, унікальний ідентифікатор вашого мобільного пристрою, IP-адресу вашого мобільного пристрою , ваша мобільна операційна система, тип мобільного Інтернет-браузера, який ви використовуєте, унікальні ідентифікатори пристрою та інші діагностичні дані.</p>
      <p>Ми також можемо збирати інформацію, яку Ваш веб-переглядач надсилає щоразу, коли Ви відвідуєте наш Сервіс або коли Ви отримуєте доступ до Сервісу за допомогою мобільного пристрою.</p>
      <h3>Технології відстеження та файли cookie</h3>
      <p>Ми використовуємо файли cookie та подібні технології відстеження, щоб відстежувати діяльність на нашому Сервісі та зберігати певну інформацію. Використовувані технології відстеження – це маяки, теги та сценарії для збору та відстеження інформації, а також для покращення та аналізу нашого Сервісу. Технології, які ми використовуємо, можуть включати:</p>
      <ul>
      <li><strong>Cookie або файли cookie веб-переглядача.</strong> Файли cookie – це невеликий файл, який розміщується на вашому пристрої.. Ви можете наказати своєму браузеру відхиляти всі файли cookie або вказувати, коли файли cookie надсилаються. Однак, якщо ви не приймаєте файли cookie, ви не зможете використовувати деякі частини нашого Сервісу. Якщо ви не налаштували параметри свого браузера так, щоб він відмовлявся від файлів cookie, наш Сервіс може використовувати файли cookie.</li>
      <li><strong>Флеш-кукі-файли.</strong> Деякі функції нашого Сервісу можуть використовувати локальні збережені об’єкти (або флеш-кукі-файли) для збору та зберігання інформації про ваші вподобання чи вашу діяльність у нашому Сервісі. Флеш-файли cookie не керуються тими ж налаштуваннями браузера, які використовуються для файлів cookie браузера. Щоб отримати додаткові відомості про те, як видалити флеш-файли cookie, прочитайте &quot;Де я можу змінити налаштування для вимкнення або видалення локальних спільних об’єктів?&quot; доступно на <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_" rel="external nofollow noopener noreferrer" target="_blank" >https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_</a></li>
      <li><strong>Веб-маяки.</strong> Певні розділи нашого Сервісу та наші електронні листи можуть містити невеликі електронні файли, відомі як веб-маяки (також називаються чіткими gif-файлами, піксельними тегами та однопіксельними gif-файлами), які дозволяють Компанія, наприклад, для підрахунку користувачів, які відвідали ці сторінки або відкрили електронний лист, і для іншої пов’язаної статистики веб-сайту (наприклад, запис популярності певного розділу та перевірка цілісності системи та сервера).</li>
      </ul>
      <p>Файли cookie можуть бути &quot;постійними&quot; або &quot;Сеанс&quot; Печиво. Постійні файли cookie залишаються на вашому персональному комп’ютері або мобільному пристрої, коли ви перебуваєте в автономному режимі, а сеансові файли cookie видаляються, щойно ви закриваєте свій веб-браузер. Дізнайтеся більше про файли cookie на <a href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking" target="_blank" rel="noreferrer">веб-сайті безкоштовної політики конфіденційності</a> стаття.</p>
      <p>Ми використовуємо як сеансові, так і постійні файли cookie для цілей, викладених нижче:</p>
      <ul>
      <li>
      <p><strong>Необхідні/основні файли cookie</strong></p>
      <p>Тип: сеансові файли cookie</p>
      <p>Адмініструється: ми</p>
      <p>Мета: Ці файли cookie необхідні для надання вам послуг, доступних через веб-сайт, і для того, щоб ви могли використовувати деякі його функції. Вони допомагають автентифікувати користувачів і запобігати шахрайському використанню облікових записів користувачів. Без цих файлів cookie послуги, про які ви запитували, не можуть бути надані, і ми використовуємо ці файли cookie лише для надання вам цих послуг.</p>
      </li>
      <li>
      <p><strong>Політика використання файлів cookie/файли cookie прийняття повідомлень</strong></p>
      <p>Тип: постійні файли cookie</p>
      <p>Адмініструється: ми</p>
      <p>Призначення: ці файли cookie визначають, чи дозволили користувачі використовувати файли cookie на веб-сайті.</p>
      </li>
      <li>
      <p><strong>Функціональні файли cookie</strong></p>
      <p>Тип: постійні файли cookie</p>
      <p>Адмініструється: ми</p>
      <p>Призначення: ці файли cookie дозволяють нам запам’ятовувати вибір, який ви робите під час користування веб-сайтом, наприклад запам’ятовувати дані для входу чи налаштування мови. Метою цих файлів cookie є надання Вам більш особистого досвіду та уникнення необхідності повторно вводити свої налаштування щоразу, коли Ви використовуєте Веб-сайт.</p>
      </li>
      </ul>
      <p>Для отримання додаткової інформації про файли cookie, які ми використовуємо, і ваш вибір щодо файлів cookie, відвідайте нашу Політику стосовно файлів cookie або розділ щодо файлів cookie нашої Політики конфіденційності.</p>
      <h2>Використання ваших персональних даних</h2>
      <p>Компанія може використовувати Персональні дані для таких цілей:</p>
      <ul>
      <li>
      <p><strong>Надавати та підтримувати наш Сервіс</strong>, зокрема контролювати використання нашого Сервісу.</p>
      </li>
      <li>
      <p><strong>Керувати своїм обліковим записом:</strong> керувати вашою реєстрацією як користувача Сервісу. Персональні дані, які ви надаєте, можуть надати вам доступ до різних функцій Сервісу, які доступні вам як зареєстрованому користувачеві.</p>
      </li>
      <li>
      <p><strong>Для виконання контракту:</strong> розробка, дотримання та укладення договору купівлі-продажу продуктів, товарів чи послуг, які ви придбали, або будь-якого іншого договору з нами через Службу.</ p>
      </li>
      <li>
      <p><strong>Щоб зв’язатися з вами:</strong> щоб зв’язатися з вами електронною поштою, телефонними дзвінками, SMS або іншими еквівалентними формами електронного зв’язку, як-от push-повідомлення мобільної програми щодо оновлень або інформаційні повідомлення, пов’язані з функціями, продуктів або контрактних послуг, включаючи оновлення безпеки, якщо це необхідно або розумно для їх впровадження.</p>
      </li>
      <li>
      <p><strong>Надавати вам</strong> новини, спеціальні пропозиції та загальні відомостіінформацію про інші товари, послуги та події, які ми пропонуємо, подібні до тих, які ви вже придбали або про які запитували, якщо ви не вирішили не отримувати таку інформацію.</p>
      </li>
      <li>
      <p><strong>Керувати вашими запитами:</strong> відвідувати та керувати вашими запитами до нас.</p>
      </li>
      <li>
      <p><strong>Для передачі бізнесу:</strong> ми можемо використовувати вашу інформацію для оцінки або проведення злиття, вилучення, реструктуризації, реорганізації, розпуску чи іншого продажу чи передачі частини або всіх наших активів, будь то як безперервне підприємство або як частина банкрутства, ліквідації чи подібного провадження, у якому Персональні дані користувачів наших Сервісів, які зберігаються в нас, є серед переданих активів.</p>
      </li>
      <li>
      <p><strong>Для інших цілей</strong>: ми можемо використовувати вашу інформацію для інших цілей, наприклад для аналізу даних, виявлення тенденцій використання, визначення ефективності наших рекламних кампаній, а також для оцінки та вдосконалення наших Сервісів, продуктів і послуг , маркетинг і ваш досвід.</p>
      </li>
      </ul>
      <p>Ми можемо надати вашу особисту інформацію в таких ситуаціях:</p>
      <ul>
      <li><strong>З Постачальниками послуг:</strong> ми можемо ділитися вашою особистою інформацією з Постачальниками послуг, щоб контролювати й аналізувати використання нашої Послуги, щоб зв’язуватися з вами.</li>
      <li><strong>Для передачі бізнесу:</strong> ми можемо ділитися або передавати вашу особисту інформацію у зв’язку з або під час переговорів щодо будь-якого злиття, продажу активів Компанії, фінансування або придбання всього чи частини нашого бізнес іншій компанії.</li>
      <li><strong>З афілійованими особами:</strong> ми можемо надати вашу інформацію нашим афілійованим особам, і в цьому випадку ми вимагатимемо від цих афілійованих осіб дотримання цієї Політики конфіденційності. До афілійованих осіб належать наша материнська компанія та будь-які інші дочірні компанії, партнери по спільним підприємствам або інші компанії, які ми контролюємо або які перебувають під спільним контролем з нами.</li>
      <li><strong>З діловими партнерами:</strong> ми можемо ділитися вашою інформацією з нашими діловими партнерами, щоб пропонувати вам певні продукти, послуги чи рекламні акції.</li>
      <li><strong>З іншими користувачами:</strong> коли ви ділитеся особистою інформацією або іншим чином взаємодієте з іншими користувачами в загальнодоступних місцях, така інформація може переглядатися всіма користувачами та може бути публічно поширена за межами.</li>
      <li><strong>З вашої згоди</strong>: ми можемо розкривати вашу особисту інформацію з будь-якою іншою метою з вашої згоди.</li>
      </ul>
      <h2>Зберігання ваших персональних даних</h2>
      <p>Компанія зберігатиме ваші персональні дані лише до тих пір, поки це необхідно для цілей, викладених у цій Політиці конфіденційності. Ми зберігатимемо та використовуватимемо ваші персональні дані в обсязі, необхідному для дотримання наших юридичних зобов’язань (наприклад, якщо від нас вимагається зберігати ваші дані для дотримання чинного законодавства), вирішення суперечок і забезпечення виконання наших юридичних угод і політик.</ p>
      <p>Компанія також зберігатиме дані про використання для цілей внутрішнього аналізу. Дані про використання, як правило, зберігаються протягом коротшого періоду часу, за винятком випадків, коли ці дані використовуються для посилення безпеки чи покращення функціональності нашого Сервісу, або якщо ми юридично зобов’язані зберігати ці дані протягом довшого періоду часу.</p>
      <h2>Передача ваших персональних даних</h2>
      <p>Ваша інформація, включно з Персональними даними, обробляється в робочих офісах Компанії та в будь-яких інших місцях, де знаходяться сторони, залучені до обробки. Це означає, що ця інформація може передаватись і зберігатися на комп’ютерах, розташованих за межами Вашого штату, провінції, країни чи іншої державної юрисдикції, де закони про захист даних можуть відрізнятися від законів Вашої юрисдикції.</p>
      <p>Ваша згода з цією Політикою конфіденційності з подальшим наданням такої інформації означає вашу згоду на таку передачу.</p>
      <p>Компанія вживатиме всіх розумно необхідних заходів, щоб гарантувати, що ваші дані обробляються безпечно та відповідно до цієї Політики конфіденційності, і жодна передача ваших особистих даних організації чи країні не здійснюватиметься, якщо не буде встановлено адекватних засобів контролю, зокрема безпека ваших даних та іншої особистої інформації.</p>
      <h2>Розкриття ваших персональних даних</h2>
      <h3>Комерційні операції</h3>
      <p>Якщо Компанія бере участь у злитті, поглинанні або продажу активів, ваші персональні дані можуть бути передані. Ми надішлемо сповіщення до того, як ваші персональні дані будуть передані та підпадають під дію іншої Політики конфіденційності.</p>
      <h3>Правоохоронні органи</h3>
      <p>За певних обставин Компанія може бути зобов’язана розкрити Ваші персональні дані, якщо цього вимагає закон або у відповідь на обґрунтовані запити державних органів (наприклад, суду чи державної установи).</p>
      <h3>Інші юридичні вимоги</h3>
      <p>Компанія може розкрити Ваші персональні дані, сумлінно вважаючи, що така дія необхідна для:</p><ul>
      <li>Дотримуватись юридичних зобов’язань</li>
      <li>Захист і захист прав чи власності Компанії</li>
      <li>Запобігання або розслідування можливих протиправних дій у зв’язку зі Службою</li>
      <li>Захищати особисту безпеку Користувачів Сервісу або громадськості</li>
      <li>Захист від юридичної відповідальності</li>
      </ul>
      <h2>Безпека ваших персональних даних</h2>
      <p>Безпека ваших особистих даних важлива для нас, але пам’ятайте, що жоден метод передачі через Інтернет або метод електронного зберігання не є на 100% безпечним. Незважаючи на те, що ми прагнемо використовувати комерційно прийнятні засоби для захисту ваших персональних даних, ми не можемо гарантувати їх абсолютну безпеку.</p>
      <h1>Конфіденційність дітей</h1>
      <p>Наша служба не звертається до осіб віком до 13 років. Ми свідомо не збираємо особисту інформацію від осіб віком до 13 років. Якщо ви є батьком або опікуном і вам відомо, що ваша дитина надала нам особисті Дані, будь ласка, зв'яжіться з нами. Якщо нам стає відомо, що ми збирали персональні дані від будь-якої особи віком до 13 років без підтвердження згоди батьків, ми вживаємо заходів для видалення цієї інформації з наших серверів.</p>
      <p>Якщо нам потрібна згода як правова основа для обробки вашої інформації, а ваша країна вимагає згоди батьків, ми можемо вимагати згоди ваших батьків, перш ніж збирати та використовувати цю інформацію.</p>
      <h1>Посилання на інші веб-сайти</h1>
      <p>Наша служба може містити посилання на інші веб-сайти, якими ми не керуємо. Якщо ви клацнете посилання третьої сторони, вас буде спрямовано на сайт цієї третьої сторони. Ми настійно рекомендуємо вам переглядати Політику конфіденційності кожного сайту, який ви відвідуєте.</p>
      <p>Ми не контролюємо та не несемо відповідальності за вміст, політику конфіденційності чи практику будь-яких сторонніх сайтів чи служб.</p>
      <h1>Зміни в цій Політиці конфіденційності</h1>
      <p>Час від часу ми можемо оновлювати нашу Політику конфіденційності. Ми повідомимо вас про будь-які зміни, опублікувавши нову Політику конфіденційності на цій сторінці.</p>
      <p>Ми повідомимо вас електронною поштою та/або у видному сповіщенні на Нашій службі до того, як зміни набудуть чинності, і оновимо &quot;Останнє оновлення&quot; дату вгорі цієї Політики конфіденційності.</p>
      <p>Рекомендуємо періодично переглядати цю Політику конфіденційності на предмет будь-яких змін. Зміни до цієї Політики конфіденційності набувають чинності, коли вони опубліковані на цій сторінці.</p>
      <h1>Зв'яжіться з нами</h1>
      <p>Якщо у вас виникли запитання щодо цієї Політики конфіденційності, ви можете зв’язатися з нами:</p>
      <ul>
      <li>Електронною поштою: info@ualyrics.com</li>
      </ul>
    </>
  );
};