/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:e2b2ad62-70cd-4766-b67b-dbfe12b6cd75",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_R7z62AYku",
    "aws_user_pools_web_client_id": "5a9jpelsl7f54t902msai9de9h",
    "oauth": {
        "domain": "ualyricsweb57e34414-57e34414-dev.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.ualyrics.com/,https://www.dev.ualyrics.com/,http://localhost:3000/",
        "redirectSignOut": "https://www.ualyrics.com/,https://www.dev.ualyrics.com/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://vxonedxywjbkljpha4xxbeiiaq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-77zdjrmanrgsvozb47nh34r7xi",
    "aws_user_files_s3_bucket": "sharedstorage-dev-ualyrics204912-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
